<template>
  <div id="viewContainer">
    <div class="view-toolbar">
      <PDFPageDisplay
          v-if="totalPage"
          v-model:toolPageIndex="toolPageIndex"
          :total-page="totalPage"
          :no-input="true"
      ></PDFPageDisplay>
    </div>
    <div class="view-content">
        <PdfPreview
            v-if="contractId"
            :contractId="contractId"
            :version-id="versionId"
            :view-ocr-mode="fileType == 2"
            ref="pdfPreview"
            :default_scale="require('../../../components/common/pdfView').ScaleSpecialValue.PAGE_WIDTH"
            :previewSuccess="pdfPreviewSuccess"
        ></PdfPreview>
      <h3 v-else>没有扫描版本</h3>
  </div>

  </div>
</template>

<script>
import PdfPreview from "../../../components/PdfPreview";
import { requestHeaderMixin} from "../../../api/api";
import PDFPageDisplay from "../../../components/contract/PDFPageDisplayer";
import {appPostMessage, DomEventListenerManager} from "../../../util";


export default {
  name: "viewVersionFileApp",
  data(){
    return {
      inhibitJumpPage:false,
      fileType:1,//1为文字版
      contractId:null,
      versionId:null,
      pdfInfo:null,
      toolPageIndex:1,
      totalPage:0,
      domEventListenerManager:new DomEventListenerManager(),
      // scrolled:false,
    }
  },
  mixins:[requestHeaderMixin],
  watch:{
    toolPageIndex(newVal){
      if(this.inhibitJumpPage) return this.inhibitJumpPage = false;
      this.setPdfPage(Number(newVal));
    },
  },
  methods:{
    pdfPreviewSuccess(pdfContainerId,pdfInfo){
      appPostMessage({type:'viewType',value:this.fileType});

      let pdfContainer = document.querySelector('#'+pdfContainerId);
      pdfContainer.scrollTop = 0;
      // this.domEventListenerManager.registerListener(pdfContainer,'scroll',(e)=>{
      //   if(e.currentTarget.scrollTop !== 0){
      //     this.scrolled = true;
      //   }else{
      //     this.scrolled = false;
      //   }
      // })

      this.setPDFInfo(pdfInfo);
      this.bindPDFEventListeners();
    },
    setPDFInfo(pdfInfo){
      this.pdfInfo = pdfInfo;
      this.totalPage = pdfInfo.pdfDoc.numPages;
    },
    bindPDFEventListeners(){
      this.pdfInfo.eventBus._on('pagechanging',(data)=>{
        this.inhibitJumpPage = true;
        this.toolPageIndex = data.pageNumber;
      })
    },
    setPdfPage(number){
      if(!this.pdfInfo) return;

      this.pdfInfo.pdfViewer.currentPageNumber = number;
    },
  },
  created() {
    this.contractId = this.$route.params.contractId;
    this.versionId = this.$route.params.versionId;

    window.receiveMessage = async (msg) => {            //在window上挂载一个receiveMessage方法，RN会调用
      let operation = msg['type'];
      if(operation == 'jumpTo'){
        this.toolPageIndex = msg.value;
        this.setPdfPage(Number(msg.value));

      }else if(operation == 'viewType'){
        this.fileType = msg.value;
      }
    }
  },
  components:{PDFPageDisplay, PdfPreview}
}
</script>

<style scoped>
p{
  margin: 0;
}

#viewContainer{
  width: 100vw;
  height: 100vh;
  display:flex;
  flex-flow: column;
  position: relative;
  overflow: hidden;
}

.view-toolbar{
  position: absolute;
  z-index: 1;
  left: 5px;
  top: 5px;
  padding:0 3px;
  transition:.4s;
  background: rgba(0,0,0,.5);
  border-radius: 4px;
}
.view-toolbar .pdf-page-display{
  color: #fff;
  pointer-events: none;

}
.view-toolbar.scrolled{
  box-shadow: 0 2px 0px var(--gray-2);
}

.view-content{
  flex: 1;
  min-height: 0px;
  width: 100%;
  position: relative;
}
/*pdf组件的内部样式*/
.view-content >>> .pdf-container{
  height: 100%;
  overflow: auto;
}

</style>
