<template>
  <div class="pdf-page-display">
    <span class="ppd-curr" v-if="noInput">{{toolPageIndex}}</span>
    <input class="ppd-input" v-else ref="pageInput" @keydown="catchKey" @change="checkPageIndex" :value="toolPageIndex"/>
    <span class="ppd-total">{{` / ${totalPage}`}}</span>
  </div>
</template>

<script>
import {colorLog} from "../../util";

export default {
  name: "PDFPageDisplay",
  props:{
    toolPageIndex:{
      required:false,
      default:1
    },
    totalPage:{
      required:true
    },
    noInput:{
      required:false,
      default:false
    }
  },
  watch:{
  },
  date(){
    return {
    }
  },
  methods:{
    /**
     * 拦截输入,进行输入的类型检查
     * */
    catchKey(e){
      // console.log(e)
      if(!/^\d+$/g.test(e.key)){
        if(e.shiftKey || e.ctrlKey || e.altKey || [8].includes(e.keyCode)){ // 8 : Backspace
          return;
        }
        if([27,13].includes(e.keyCode)){ // 27:esc 13:enter
          e.target.blur();
        }
        //含有非数字,阻止输入
        e.preventDefault();
        e.stopPropagation();

      }
    },
    /**
     * 对输入的页面值进行检查修正
     */
    checkPageIndex(){
      let newValue = this.$refs.pageInput.value;
      if(typeof newValue !== "number" && isNaN(Number(newValue))){
        newValue = 1;
      }else if(Number(newValue) > this.totalPage){
        newValue = this.totalPage;
      }else if(Number(newValue) < 1){
        newValue = 1;
      }

      if(newValue !== this.$refs.pageInput.value || newValue !== this.toolPageIndex){
        this.$refs.pageInput.value = newValue;
        this.$emit('update:toolPageIndex',newValue);
      }
    },
  }
}
</script>

<style scoped>
.ppd-input,
.ppd-total,
.ppd-curr{
  vertical-align: middle;
}

.ppd-input{
  width: 35px;
  padding: 5px;
  height: 80%;
  box-sizing: border-box;
  text-align: right;
  border: 1px solid var(--lightBlue);
  border-radius: 5px;
}
.ppd-total{
  white-space: pre;
  user-select:none;
  position: relative;
}
</style>
